<template>
  <q-list padding dense class="menu-active-plus q-mt-sm">
    <q-item clickable class="q-mb-md" v-for="(ruta, index) in rutasMenu" :key="index"
            exact
            :class="{ 'q-item--active': validarActiveRuta(ruta.menuLateral) }">
      <q-item-section class="text-center q-py-xs">
        <q-item-label>
          <q-icon :name="ruta.icono" size="28px"/>
        </q-item-label>
        <q-item-label class="fs-7-5 text-uppercase">{{ ruta.titulo }}</q-item-label>
      </q-item-section>
      <q-menu anchor="top right" self="top left" auto-close square v-if="ruta.menuLateral.length > 0"
              content-class="lateral-menu">
        <div class="row w100p text-white q-py-md q-px-lg">
          <div class="col-12 q-py-sm q-px-md">
            <q-list padding dense>
              <template v-for="(menu, index) in ruta.menuLateral">
                <q-item :key="index" class="q-mb-md">
                  <q-item-section>
                    <q-item-label header class="text-uppercase text-title-menu">{{ menu.titulo }}</q-item-label>
                    <q-list dense>
                      <template v-for="(opcion, indexOpt) in menu.opciones">
                        <q-item clickable :to="{name:opcion.ruta}" :key="indexOpt" exact
                                :class="{'q-item--active q-active': validarRutasHijos(opcion)}">
                          <q-item-section>
                            <q-item-label>{{ opcion.label }}</q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-item-section>
                </q-item>
              </template>
            </q-list>
          </div>
        </div>
      </q-menu>
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: "menuOperacion",
  data() {
    return {
      rutasMenu: [
        {
          titulo: "Config", icono: "las la-cog", menuLateral:
              [
                {
                  titulo: "Configuración", opciones:
                      [
                        {ruta: "home", label: "Home", rutasRelacionadas: []},
                        {ruta: "usuariosGestor", label: "Usuarios", rutasRelacionadas: []},
                        {ruta: "usuarioAgregar", label: "Agregar usuario", rutasRelacionadas: []},
                      ]
                },
              ]
        },
      ]
    }
  },
  methods: {
    validarActiveRuta(menuLateral) {
      let rutaValida = false;
      menuLateral.forEach(ml => {
        ml.opciones.forEach(r => {
          if (this.$route.name.includes(r.ruta) || _.filter(r.rutasRelacionadas, (rr) => this.$route.name === rr).length > 0)
            rutaValida = true;
        });
      });
      return rutaValida;
    },
    validarRutasHijos(opcion) {
      return (opcion.ruta !== "" &&
          (this.$route.name === opcion.ruta ||
              _.filter(opcion.rutasRelacionadas, (rr) => this.$route.name === rr).length > 0)
      );
    },
  }
}
</script>
